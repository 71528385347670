@import "mixins";
@import "variables";

.account-area {
    img {
        border: 5px solid transparent;
        border-radius: 50%;
        transition: all 0.3s ease;
    }

    a {
        color: $black;

        @media (hover: hover) {
            &:hover {
                color: $purple;

                img {
                    border-color: $purple;
                }
            }
        }
    }

    h5 {
        margin-top: 1rem;
    }
}

.alert {
    border-width: 2px;
}

.alert-danger {
    background-color: $color-danger;
    border-color: $color-danger-bold;
}

.banner-home {
    margin-top: 0 !important;
    margin-bottom: 6rem !important;
    position: relative;

    @media screen and (min-width: $screen-lg-min) {
        margin-bottom: 9rem !important;
    }

    .hero-banner {
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        height: 100vh;
        min-height: 400px;
        max-height: 600px;
        width: 100%;

        @media screen and (min-width: $screen-lg-min) {
            height: 80vh;
            min-height: 800px;
            max-height: 1200px;
        }

        .overlay {
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            display: flex;
            align-items: flex-end;
            height: 100%;
            width: 100%;

            .container {
                color: #FFF;

                .banner-text {
                    font-weight: 500;
                    font-size: 2rem;
                    font-family: Hind;
                    line-height: 1.2;
                    max-width: 100%;
                    text-align: center;
                    text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
                    margin: 0 auto 8rem auto;
                    padding: 0 3rem;

                    @media screen and (min-width: $screen-md-min) {
                        font-size: 3rem;
                        text-align: left;
                    }

                    @media screen and (min-width: $screen-xl-min) {
                        padding: 0;
                    }
                }
            }
        }
    }

    .carousel-controls {
        position: absolute;
        width: 150px;
        bottom: 3rem;
        height: 50px;
        left: 0;
        right: 0;
        margin: 0 auto;

        @media screen and (min-width: $screen-md-min) {
            width: 100%;
            bottom: 0;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .carousel-control-prev, .carousel-control-next {
        background-color: $light-blue;
        height: 50px;
        width: 50px;
        opacity: 0.8;
        border-radius: 50px;

        &:hover {
            opacity: 1;
        }
    }

    .carousel-control-next {
        @media screen and (min-width: $screen-md-min) {
            margin-right: 3rem;
        }
    }

    .carousel-control-prev {
        @media screen and (min-width: $screen-md-min) {
            margin-left: 3rem;
        }
    }
}

.banner {
    margin-top: 0 !important;
    margin-bottom: 9rem !important;
    position: relative;
    overflow-x: clip;

    &.inner {
        margin-bottom: 3rem !important;

        .hero-banner {
            clip-path: polygon(0 0,100% 0,100% 90%,0 100%);

            .overlay {
                background: none !important;
                display: flex;
                align-items: flex-start;

                .container {
                    .banner-text {
                        background-color: $light-blue;
                        display: inline-block;
                        padding: 2rem;
                        width: auto;
                        align-self: center;
                        margin-top: 100px;

                        .title {
                            margin-bottom: 0;
                        }

                        .text {
                            font-size: 1rem;
                        }

                        .link {
                            color: $white;
                        }
                    }
                }
            }
        }
    }

    .feature {
        position: absolute;
        z-index: 3;
        bottom: -100px;
        right: 0;

        @include fade-in(1s,1s);

        @media screen and (min-width: $screen-md-min) {
            bottom: -50px;
            width: 400px;
        }

        @media screen and (min-width: $screen-lg-min) {
            bottom: 0;
            width: 500px;
        }

        @media screen and (min-width: $screen-xl-min) {
            width: 600px;
        }

        @media screen and (min-width: $screen-xxl-min) {
            width: 800px;
        }

        img {
            height: 200px;

            @media screen and (min-width: $screen-md-min) {
                height: auto;
            }
        }
    }

    .ribbon {
        position: absolute;
        z-index: 2;
        top: 0;
        display: none;

        @include fade-in(1s,.75s);

        @media screen and (min-width: $screen-lg-min) {
            display: block;
            right: -300px;
            width: 800px;
        }

        @media screen and (min-width: $screen-xxl-min) {
            right: -200px;
        }
    }

    .hero-banner {
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        clip-path: polygon(0 0%,100% 0,100% 100%,0 95%);
        height: auto;
        width: 100%;

        @media screen and (min-width: $screen-md-min) {
            clip-path: polygon(0 0%,100% 0,100% 100%,0 90%);
        }

        @media screen and (min-width: $screen-lg-min) {
            height: 75vh;
            min-height: 600px;
            max-height: 1000px;
            clip-path: polygon(0 0%,100% 0,100% 100%,0 80%);
        }

        .overlay {
            background: rgba(4,37,62,0.85);
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            height: 100%;
            width: 100%;

            .container {
                color: #FFF;

                .banner-text {
                    max-width: 100%;
                    padding: 3rem 3rem 120px 3rem;
                    width: 600px;
                    text-align: center;
                    margin: 0 auto;

                    @media screen and (min-width: $screen-md-min) {
                        text-align: left;
                        margin: unset;
                    }

                    @media screen and (min-width: $screen-lg-min) {
                        width: 600px;
                        padding: 6rem 0 0 0;
                        text-align: left;
                        margin: unset;
                    }

                    .text {
                        @media screen and (min-width: $screen-lg-min) {
                            font-size: 1.4rem;
                        }
                    }

                    .title {
                        font-size: 2rem;
                        font-family: Hind;
                        font-weight: 700;
                        line-height: 1.2;

                        @media screen and (min-width: $screen-lg-min) {
                            font-size: 3.6rem;
                        }
                    }
                }
            }
        }
    }
}

.banner-bottom {
    .hero-banner {
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        height: calc(100vh - 200px);
        width: 100%;

        .overlay {
            background: linear-gradient(270deg,#001E34 0%,rgba(0,30,52,0.5) 100%);
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            height: 100%;
            overflow: hidden;
            position: relative;
            display: flex;
            align-items: center;
            width: 100%;

            @media screen and (min-width: $screen-lg-min) {
                background: linear-gradient(270deg,#001E34 0%,rgba(0,30,52,0.15) 100%);
                justify-content: flex-end;
            }

            .banner-text {
                color: $white;
                font-size: 1rem;
                text-align: center;
                padding: 0 1rem;

                @media screen and (min-width: $screen-lg-min) {
                    width: 650px;
                    max-width: 100%;
                    float: right;
                    margin-right: 10%;
                    text-align: left;
                }

                .text {
                    margin-bottom: 4rem;
                }

                header {
                    color: $white !important;
                    text-align: center!important;

                    @media screen and (min-width: $screen-lg-min) {
                        text-align: left!important;
                    }
                }
            }
        }
    }
}

.bespoke-solutions {
    .container {
        border-top: solid 3px $light-grey;
        border-bottom: solid 3px $light-grey;

        .max-container {
            width: 1000px;
            margin: 3rem auto;
            max-width: 100%;
        }
    }
}

.cable-assemblies {
    margin-bottom: 6rem!important;

    .container {
        display: block;
        align-items: center;
        position: relative;

        @media screen and (min-width: $screen-md-min) {
            display: flex;
        }

        .text {
            position: static;
            padding: 0;
            margin-bottom: 15px;
            background-color: $light-grey;
            z-index: 5;
            right: unset;
            padding: 2rem 2rem 0 2rem;

            @media screen and (min-width: $screen-md-min) {
                position: absolute;
                margin-bottom: 0;
                max-width: 100%;
                width: 600px;
                top: -4rem;
                right: 2rem;
            }

            @media screen and (min-width: $screen-lg-min) {
                position: absolute;
                margin-bottom: 0;
                top: unset;
                right: 0.75rem;
            }
        }

        .image {
            margin-right: auto;
            position: relative;
            width: 900px;
            max-width: 100%;

            img {
                @media screen and (min-width: $screen-lg-min) {
                    clip-path: polygon(0 0%,100% 0,85% 100%,0 100%);
                }
            }
        }
    }
}

.card {
    margin-bottom: 1rem;

    .card-header {
        background-color: white;
        border-bottom: 0;
        padding-bottom: 0;
        padding-top: 1.25rem;

        h2 {
            margin-bottom: 0;
        }
    }
}

.cursor-pointer {
    cursor: pointer;
}

.contact-form {
    width: 900px;
    max-width: 100%;
    margin: 3rem auto 0 auto;

    .btn {
        width: 100%;
        font-size: 1rem;
        padding: 0.75rem 1.5rem;
    }

    .card {
        border: none;
        box-shadow: none !important;

        .card-body {
            padding: 0;
        }

        .form-group {
            &:nth-child(1), &:nth-child(3) {
                float: left;
                margin-right: 0;
                width: 100%;

                @media screen and (min-width: $screen-sm-min) {
                    width: calc(50% - 15px);
                    margin-right: 30px;
                }
            }

            &:nth-child(2), &:nth-child(4) {
                float: left;
                width: 100%;

                @media screen and (min-width: $screen-sm-min) {
                    width: calc(50% - 15px);
                }
            }

            &:last-child {
                clear: both;
            }
        }

        textarea {
            width: 100%;
            height: 400px;
        }

        input[type="text"], input[type="password"], select {
            height: 50px;
            line-height: 38px; /* make sure text is vertically aligned centrally (height - padding - border) */
            width: 100%;
        }

        .card-header {
            display: none;
        }
    }
}

//Overwritting some BS5 styles I personally don't like
.container, .container-md, .container-sm {
    @media screen and (max-width: $screen-md-max) {
        max-width: 100%;
    }
}

.link {
    display: inline-block;
    font-weight: 700;
    position: relative;
    padding-right: 30px;

    &:after {
        content: url(../Images/arrowwhite.png);
        position: absolute;
        top: 1px;
        bottom: 0;
        right: 0;
    }
}

.pagination {
    margin-bottom: 0;

    .page-item {
        .page-link {
            color: $dark-blue;
        }

        &.active {
            .page-link {
                background-color: $dark-blue;
                border-color: $dark-blue;
                color: white;
            }
        }
    }
}

.sectors-we-specialise-in {
    background-color: $dark-blue;
    font-size: 1.1rem;
    padding: 7rem 0 47rem 0;
    clip-path: polygon(0 0%,100% 5%,100% 100%,0 80%);
    color: $white;

    @media screen and (min-width: $screen-md-min) {
        clip-path: polygon(0 0%,100% 10%,100% 100%,0 80%);
        padding: 10rem 0 50rem 0;
    }

    @media screen and (min-width: $screen-lg-min) {
        clip-path: polygon(0 0%,100% 15%,100% 100%,0 80%);
        padding: 14rem 0 50rem 0;
    }

    header {
        color: $white !important;

        span {
            color: $light-blue !important;
        }
    }
}

.sectors {
    margin-top: -50rem !important;

    .sector-grid {
        display: grid;
        grid-template-columns: repeat(1,1fr);
        gap: 1rem;

        @media screen and (min-width: $screen-sm-min) {
            grid-template-columns: repeat(2,1fr);
        }

        @media screen and (min-width: $screen-xl-min) {
            grid-template-columns: repeat(3,1fr);
        }

        .sector-wrapper {
            height: 250px;
            overflow: hidden;
            position: relative;

            .sector {
                background-repeat: no-repeat;
                background-position: center center;
                background-size: cover;
                height: 100%;
                width: 100%;
                display: block;
                box-shadow: 0 1px 2px rgba(0,0,0,.15);
                transition: all 0.5s ease;

                @media screen and (min-width: $screen-md-min) {
                    background-size: 115%;
                }

                &:hover {
                    cursor: pointer;

                    @media screen and (min-width: $screen-md-min) {
                        background-size: 120%;
                    }
                }

                .overlay {
                    background: linear-gradient(to bottom, rgba(0,0,0,0.1) 0%,rgba(0,0,0,0.2) 42%,rgba(0,0,0,0.3) 56%,rgba(0,0,0,0.9) 100%);
                    height: 100%;
                    left: 0;
                    position: absolute;
                    overflow: hidden;
                    top: 0;
                    width: 100%;
                    transform-style: preserve-3d;
                    transition: background-color .3s ease-in;

                    &:hover {
                        background-color: rgba(92,162,216,0.8);
                        text-decoration: none;
                    }

                    .text {
                        color: #FFF;
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        left: 0;
                        padding: 0 1rem;
                        z-index: 2;

                        h4 {
                            color: #FFF;
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}

.services {
    margin-bottom: 9rem !important;

    .service {
        margin-top: 1rem;

        .image {
            background-color: #D1EBFF;

            &:hover {
                img {
                    clip-path: polygon(0 0%,100% 0,100% 100%,0 100%);
                }
            }

            img {
                clip-path: polygon(0 0%,100% 0,100% 100%,0 90%);
                transition: clip-path 0.3s ease;
            }
        }

        .text {
            background-color: #D1EBFF;
            padding: 1rem 1.5rem;

            h4 {
                a {
                    color: $black;

                    &:hover {
                        color: $purple;
                    }
                }
            }
        }
    }
}

.table {
    td {
        vertical-align: middle;
    }
}

.talk-to-our-experts {
    .blue-box {
        background-color: $dark-blue;
        border-radius: 1rem;
        color: $white;
        padding: 2rem;

        .row {
            align-items: center;

            h2 {
                color: $white;
            }

            p {
                margin-bottom: 0;
            }
        }
    }
}

.truncate {
    @include truncate;
}