@import "variables";

.btn-check:active + .btn-outline-primary, .btn-check:checked + .btn-outline-primary, .btn-outline-primary:active {
    background-color: $purple;
    border-color: $purple;
    box-shadow: none !important;
}

.btn-primary {
    background-color: $purple;
    border-color: $purple;
    border-radius: 0;
    box-shadow: none !important;

    @media (hover: hover) {
        &:hover {
            background-color: $dark-blue;
            border-color: $dark-blue;
        }
    }

    &:focus {
        background-color: $dark-blue;
        border-color: $dark-blue;
    }
}

.btn-secondary {
    background-color: transparent;
    border-width: 2px;
    border-color: $dark-blue;
    border-radius: 0;
    color: $dark-blue;
    box-shadow: none !important;

    @media (hover: hover) {
        &:hover {
            background-color: $dark-blue;
            color: #FFF;
        }
    }

    &:focus {
        background-color: $dark-blue;
        border-color: $dark-blue;
        color: #FFF;
    }
}

.btn-lg {
    font-size: 1rem;
    padding: 0.75rem 1.5rem;
}

.field-validation-error {
    color: $color-danger-bold;
    display: inline-block;
    margin-top: 0.2rem;
}

.form-check-input:focus, .form-control:focus, .form-select:focus {
    box-shadow: none;
}

.form-check-input {
    margin-top: 0.25rem;

    &:checked {
        background-color: $dark-blue;
        border-color: $dark-blue;
    }
}

.form-group {
    margin-bottom: 1rem;

    &.required > .form-label:after {
        content: " *";
    }
}

.form-control, .form-select {
    background-color: $light-grey;
    border-radius: 0;
    border-color: $light-grey;
    transition: border-color 0.3s ease;

    &:hover, &:focus {
        border-color: $purple;
    }

    &:disabled {
        background-color: $light-grey;
        color: #c1c1c1;
        opacity: 0.8;
    }
}

.has-error {
    .form-control, .form-select {
        border-color: $color-danger-bold;
    }
    // This makes sure the preset option doesn't have an error border.
    .form-file .form-select {
        border-color: #ced4da;
    }
}

select {
    &.form-control {
        appearance: auto;
    }
}

.modal {
    .modal-dialog {
        max-width: 800px;
    }
}

.validation-summary-errors {
    background-color: $color-danger;
    border: 2px solid $color-danger-bold;
    border-radius: 0.25rem;
    font-weight: bold;
    margin: 1rem 0;
    padding: 1rem;

    span {
        display: block;
    }

    ul {
        margin-bottom: 0;
    }
}

.validation-summary-valid {
    display: none;
}
