@import "variables";

.text-grey {
    color: $color-grey;
}

.text-lg {
    font-size: 1.2rem;
}

.text-sm {
    font-size: 0.8rem;
}