@import "mixins";
@import "variables";

body.one-column-layout {
    #content {
        padding: 0 0 15px;
    }
}

body.two-columns-layout {
    #content {
        padding-right: 0;
    }
}

body.home-page {
    /*#app > header {
        box-shadow: none;
        position: absolute;
        z-index: 3000;
        width: 100%;
        top: 40px;

        .cart-text p, .cart-text a, .subtotal {
            color: #FFF;
        }

        .cart-text {
            border-right: solid 1px rgba(255,255,255,0.6);
        }

        #menu {
            background-color: transparent;
            box-shadow: none;

            #menu-navbar > ul li a {
                color: #FFF;
            }

            .navbar {
                border-top: solid 2px rgba(255,255,255,0.6);
            }
        }

        .shop-search-widget {
            ::placeholder {
                color: #FFF;
            }

            input {
                background-color: transparent;
                border-color: rgba(255,255,255,0.6);
            }
        }
    }*/

    #breadcrumb {
        display: none;
    }

    #outer-wrapper {
        background-image: url(../Images/HomeBackground.png);
        background-size: 110%;
        background-position: center center;
        background-repeat: no-repeat;

        @media screen and (min-width: $screen-md-min) {
            background-size: cover;
        }
    }

    .home-page-text {
        @media screen and (min-width: $screen-md-min) {
            padding-left: 5rem;
        }
    }
}