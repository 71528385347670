@import "variables";
@import "mixins.scss";

.brand {
    display: table;
    margin-bottom: 15px;
    text-align: center;

    a {
        background-color: white;
        border: 1px solid #ccc;
        display: table-cell;
        height: 150px;
        vertical-align: middle;
        width: 150px;
    }
}

#cart {
    .card {
        /* Resetting the box model properties */
        display: block;
        width: auto;
        margin: 0;
        padding: 0;
        /* Resetting the visual styles */
        background-color: #fff;
        border: none;
        border-radius: 0;
        box-shadow: none;

        &.shadow {
            box-shadow: unset !important;
        }
    }

    .card-header,
    .card-footer {
        /* Resetting the background and border styles for header and footer */
        background-color: transparent;
        border: none;
    }

    .card-body {
        /* Resetting the padding and margin for the body */
        padding: 0;
        margin: 0;
    }
}

.cart-items, .order-lines {
    & > :not(caption) > * > * {
        border-bottom-width: 3px;
        border-color: $light-grey;
        padding: 2rem .5rem;
    }

    th {
        padding: .5rem .5rem;
    }

    .description > a {
        color: $black;
        font-size: 1.1rem;
        font-weight: 700;

        @media (hover: hover) {
            &:hover {
                color: $dark-blue;
            }
        }
    }

    .item, .line {
        background-color: #FFF;
    }

    .child {
        background-color: #f8f8f8;
    }

    .image {
        align-items: center;
    }
}

.cart-summary-container {
    background-color: $light-grey;
    padding: 1rem;

    .btn {
        width: 100%;
    }

    .card {
        background-color: $light-grey;
        box-shadow: none !important;
        border: none;

        .card-body {
            background-color: $light-grey;
            padding: 0;

            .form-control {
                background-color: $white;
                border-color: $white;

                &:hover {
                    border-color: $dark-blue;
                }
            }
        }

        .discount, .summary, .delivery {
            h2 {
                font-size: 1.6rem;
            }
        }

        .delivery-methods {
            background-color: #fff;
            padding: 1rem;
            margin-bottom: 1rem;
            max-height: 300px;
            overflow-y: auto;

            .delivery-method {
                align-items: center;
                padding: 0.25rem 0;
            }
        }

        hr {
            display: none;
        }

        .summary, .totals {
            margin-bottom: 1rem;
        }
    }
}

.cart-widget {
    position: relative;

    .cart-summary {
        display: flex;
        align-items: center;
        column-gap: 1rem;

        .cart-text {
            @media screen and (min-width: $screen-lg-min) {
                border-right: solid 1px $light-grey;
                padding-right: 1rem;
            }

            .shop-link {
                color: $black;

                &:hover {
                    color: $purple;
                }
            }
        }

        .subtotal {
            color: $light-blue;
            font-size: 1.1rem;
            padding: 0 1rem;
            display: none;

            @media screen and (min-width: $screen-lg-min) {
                display: block;
            }
        }

        .cart-icon {
            align-self: baseline;
        }
    }

    .cart-items {
        background-color: $purple;
        border-radius: 50%;
        color: #fff !important;
        font-size: 0.7rem;
        line-height: 25px;
        height: 25px;
        position: absolute;
        text-align: center;
        right: -10px;
        top: 0;
        width: 25px;
    }

    .dropdown {
        background-color: white;
        border: 1px solid #888;
        border-top: 4px solid $dark-blue;
        color: $black;
        margin-top: -4px;
        padding: 15px;
        position: absolute;
        right: 0;
        width: 380px;
        z-index: 200;

        .item {
            border-bottom: 1px solid $dark-blue;
            min-height: 80px;
            padding: 10px;

            .img {
                height: 50px;
            }

            &:hover {
                background-color: #eaeaea;
            }
        }

        @media screen and (max-width: $screen-md-max) {
            display: none;
        }
    }
}

#featured-products, .top-sellers {
    .carousel-control-next, .carousel-control-prev {
        border: solid 2px $dark-blue;
        border-radius: 100%;
        height: 50px;
        width: 50px;
        margin-top: 0;
    }

    .carousel-control-next-icon, .carousel-control-prev-icon {
        width: 1rem;
        height: 1rem;
    }

    .carousel-control-next-icon {
        background-image: url(../Images/next.svg);
    }

    .carousel-control-prev-icon {
        background-image: url(../Images/previous.svg);
    }

    .product-listing {
        margin-bottom: 1rem;
    }
}

.filter-and-sort {
    &.sort, &.filter {
        display: flex;
        align-items: center;
        column-gap: 5px;
        font-weight: 700;
        padding: 0.75rem;

        @media screen and (min-width: $screen-sm-min) {
            padding: 0.75rem 1.5rem;
        }

        img {
            display: none;

            @media screen and (min-width: $screen-sm-min) {
                display: block;
            }
        }

        &:after {
            content: "";
            display: inline-block;
            border: 0;
            mask-image: url(../Images/Shop/dropdown.svg);
            background-repeat: no-repeat;
            background-position: center center;
            margin-left: 0.5rem;
            height: 7px;
            width: 13px;
            top: 3px;
        }
    }

    &.filter {
        &:after {
            background-color: white;
        }
    }

    &.sort {
        background-color: transparent;
        border: solid 2px $light-grey;
        color: $black;

        &:after {
            background-color: black;
        }

        @media (hover: hover) {
            &:hover {
                background-color: $light-grey;
            }
        }
    }
}

.flag {
    color: $white;
    font-size: 0.8rem;
    font-weight: 700;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 0.25rem 0.5rem;
    text-transform: uppercase;
    z-index: 3;

    &.red, &.sale {
        background-color: #AC1734;
    }

    &.light-blue {
        background-color: $dark-blue;
    }

    &.dark-blue {
        background-color: $dark-blue;
    }

    &.purple {
        background-color: $dark-blue;
    }
}

.product-listing {
    margin-bottom: 3rem;

    &.enquiry {
        .product-image {
            border: solid 1px $dark-blue;
            margin-bottom: 0;
            padding: 1rem;
        }

        .summary {
            background-color: $dark-blue;
            color: $white;
            padding: 2rem 1rem 1rem 1rem;

            h4, h4 a {
                color: $white;  
                
                &:hover {
                    color: $light-blue;
                }
            }

            .product-summary {
                margin-bottom: 2rem;
            }
        }
    }

    .product-image {
        background-color: $white;
        display: block;
        margin-bottom: 1.5rem;
        position: relative;
        width: 100%;
        height: 250px;

        @media screen and (min-width: $screen-md-min) {
            height: 300px;
        }

        img {
            object-fit: contain;
            width: 100%;
            height: 100%;
        }
    }

    .product-name {
        font-size: 1.2rem;
        margin-bottom: 0.5rem;

        a {
            color: $dark-blue;

            @media (hover: hover) {
                &:hover {
                    color: $purple;
                }
            }
        }
    }

    .product-price {
        font-family: Hind;
        font-weight: 700;
        font-size: 1.6rem;

        .from {
            font-size: 0.8rem;
            font-weight: 400;
        }

        .strikethrough {
            text-decoration: line-through;
            font-size: 1rem;
        }
    }
}

.product-listing-categories {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    column-gap: 30px;
    row-gap: 30px;
    margin: 2rem 0;

    @media screen and (min-width: $screen-md-min) {
        grid-template-columns: repeat(3,1fr);
    }

    .product-listing-category {
        text-align: center;

        .category-image {
            margin-bottom: 1rem;
            width: 100%;

            img {
                object-fit: cover;
                aspect-ratio: 4 / 3;
            }
        }

        h4 {
            a {
                color: $dark-blue;

                @media (hover: hover) {
                    &:hover {
                        color: $purple;
                    }
                }
            }
        }
    }
}

.product-listing-details {
    > .row {
        padding-bottom: 2rem;
    }

    .carousel-indicators {
        list-style: none;
        margin: 10px auto 0;
        overflow: hidden;
        position: static;
        width: auto;
        flex-wrap: wrap;
    }

    .carousel-indicators button, .carousel-indicators button.active {
        width: 100px;
        height: 70px;
        background-color: #fff;
        border-radius: 0;
        padding: 0.5rem;
        position: relative;
        margin: 5px;
    }

    .carousel-indicators img {
        position: absolute;
        width: 100%;
        height: auto;
        max-height: 100px; //fixes thumbnails that are portrait
        margin: auto;
        padding: 10px;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }

    .product-selection-wrapper {
        padding: 0;

        @media screen and (min-width: $screen-md-min) {
            padding: 0 1rem;
        }

        h1 {
            margin-bottom: 0.5rem;
        }

        .form-group {
            &.quantity {
                margin-top: 1rem;
                margin-bottom: 2rem;
                text-align: center;
                justify-content: space-between;
                flex-wrap: wrap;

                .form-label {
                    text-align: left;
                    width: 100%;
                }

                input {
                    background-color: white;
                    width: 135px;
                    text-align: center;
                }

                .btn {
                    line-height: 48px;
                    width: calc(100% - 150px);
                }
            }
        }

        .enquiry-product {
            background-color: $light-grey;
            border-radius: 1rem;
            margin-top: 1.5rem;
            padding: 2rem 2rem 1rem 2rem;
        }

        .need-help {
            background-color: $light-grey;
            border-radius: 1rem;
            padding: 2rem 2rem 1rem 2rem;
            text-align: center;
        }

        .price {
            font-family: Hind;
            font-size: 1.8rem;
            font-weight: 700;

            &.enquiry {
                color: $purple;
            }

            .from {
                font-size: 1rem;
                font-weight: 400;
            }

            .sale-price {
                font-size: 1rem;
                font-weight: 400;
                text-decoration: line-through;
            }
        }

        &:has(.stock-status) .sku {
            border-top: none;
        }

        .sku {
            border-top: solid 1px #CFCFCF;
            border-bottom: solid 1px #CFCFCF;
            padding: 0.5rem 0;
        }

        .stock-status {
            border-top: solid 1px #CFCFCF;
            border-bottom: solid 1px #CFCFCF;
            margin-bottom: 0;
            margin-top: 0.5rem;
            padding: 0.5rem 0;
            display: flex;

            &:before {
                content: "";
                background-image: url(../Images/Shop/Delivery.png);
                height: 24px;
                width: 39px;
                display: inline-block;
                background-repeat: no-repeat;
                background-position: center center;
                margin-right: 0.5rem;
                transition: background-color 0.3s ease;
            }
        }
    }

    .product-image {
        cursor: pointer;
        display: block;
        height: 300px;
        margin: 10px auto;
        padding: 0;
        position: relative;
        text-align: center;

        @media screen and (min-width: $screen-md-min) {
            height: 500px;
        }

        img {
            margin: auto;
            max-height: 300px;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;

            @media screen and (min-width: $screen-md-min) {
                max-height: 500px;
            }
        }
    }
}

.product-listing-details-body {
    background-color: $dark-blue;
    color: $white;
    clip-path: polygon(0 0,100% 0,100% 100%,0 97%);
    padding: 3rem 0 6rem 0;

    @media screen and (min-width: $screen-md-min) {
        clip-path: polygon(0 0,100% 0,100% 100%,0 90%);
        padding: 3rem 0 12rem 0;
    }

    .h1, h2, h3, h4, h5, h6 {
        color: $white;

        span {
            color: $light-blue
        }
    }
}

.shop-categories {
    margin-bottom: 3rem;

    .intro-box {
        background-color: $dark-blue;
        color: $white;
        padding: 30px 45px;

        @media screen and (min-width: $screen-md-min) {
            height: 300px;
        }

        h4 {
            color: $white;
            margin-bottom: 1rem;
        }

        .sub-heading {
            font-size: 1.4rem;
            font-weight: 200;
        }

        .text {
            width: 70%;
        }
    }

    .shop-category {
        background-size: cover;
        display: block;
        height: 300px;
        text-align: center;
        position: relative;
        margin-top: 1rem;

        @media screen and (min-width: $screen-md-min) {
            margin-top: 0;
        }

        &:hover {
            .overlay {
                background-color: rgba(0,0,0,0.4) !important;
            }
        }

        .overlay {
            background: linear-gradient(to bottom,rgba(0,0,0,0) 0%,rgba(0,0,0,0) 35%,rgba(0,0,0,.55) 70%,rgba(0,0,0,.95) 100%);
            bottom: 0;
            color: #fff;
            display: block;
            position: absolute;
            height: 100%;
            right: 0;
            text-align: right;
            width: 100%;
            vertical-align: bottom;
            -o-transition: all .2s ease-in;
            -moz-transition: all .2s ease-in;
            -webkit-transition: all .2s ease-in;
            transition: all .2s ease-in;
        }

        .text {
            bottom: 0;
            color: #FFF;
            left: 1rem;
            margin: 1rem 0 0 0;
            padding: 10px 0;
            position: absolute;
            text-align: left;
            width: calc(100% - 2rem);

            h4 {
                color: $white;
                margin-bottom: 0;
            }

            p {
                margin-bottom: 0;
            }
        }
    }
}

.shop-search-widget {
    position: relative;

    @media screen and (min-width: $screen-md-min) {
        width: 100%;
    }

    ::placeholder {
        color: $dark-blue;
    }

    input[type="text"] {
        background-color: $white;
        border: solid 2px $light-grey;
        border-radius: 0;
        color: $dark-blue;
        float: left;
        height: 54px;
        line-height: 45px;
        width: 100%;

        &:hover , &:focus {
            border-color: $purple;
        }
    }

    button {
        border: none !important;
        height: 50px;
        line-height: 50px;
        width: 24px;
        position: absolute;
        top: 2px;
        right: 15px;

        &.btn {
            background: url(/assets/theme/images/search.svg) no-repeat center center;
            text-indent: -9999px;

            @media (hover: hover) {
                &:hover {
                    background: url(/assets/theme/images/search.svg) no-repeat center center !important;
                }
            }
        }
    }

    .dropdown {
        background-color: white;
        border: 1px solid #888;
        left: 0;
        padding: 15px;
        position: absolute;
        top: 54px;
        width: 100%;
        z-index: 200;

        .title {
            font-weight: 700;
        }
    }
}