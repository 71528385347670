@import url('https://fonts.googleapis.com/css2?family=Hind:wght@400;500;700&family=Nunito:ital,wght@0,400;0,700;1,400&display=swap');
@import "variables";

body {
    color: #232323;
    font: 0.9rem/1.7 "Nunito", sans-serif;
    -ms-overflow-style: scrollbar; /* prevent ie scrollbar overlay */
    font-display: swap;

    @media screen and (min-width: $screen-md-min) {
        font: 1rem/1.7 "Nunito", sans-serif;
    }
}

a {
    color: $light-blue;
    text-decoration: none;
    transition: color 0.3s ease;

    &:focus {
        outline: none;
        text-decoration: none;
    }

    @media (hover: hover) {
        &:hover {
            color: $purple;
        }
    }
}

h1, h2, h3, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    color: $black;
    font-family: Hind;
    font-weight: 700;
}

h1, .h1 {
    margin-top: 1rem;
    margin-bottom: 2rem;

    span {
        color: $purple;
        font-size: 1.4rem;
        font-weight: 400;
    }
}

h2, h3, h3, h4, h5, h6, .h2, .h3, .h4, .h5, .h6 {
    margin-bottom: 1rem;
}

code {
    border: 1px solid black;
}

label {
    font-weight: 700;
}

hr {
    background-color: $light-grey;
    border: 0;
    height: 1px;
    margin: 1rem 0;
}

img {
    max-width: 100%;
}

[v-cloak] {
    display: none;
}

// Fixes v-show directive with server rendered classes with !important, e.g. d-inline. Reference: https://github.com/vuejs/vue/issues/3761#issuecomment-251545116
*[style*="display: none"] {
    display: none !important;
}

// Fixes jQuery show function with server rendered classes with !important, e.g. d-block.
*[style*="display: block"] {
    display: block !important;
}

