@mixin clearfix {
    &:before, &:after {
        content: " ";
        display: table;
    }

    &:after {
        clear: both;
    }
}

@mixin fade-in($duration: 1s, $delay: 0s) {
    animation: fade-in $duration cubic-bezier(0.390, 0.575, 0.565, 1.000) $delay both;
}

@mixin fade-out($duration: 1s, $delay: 0s) {
    animation: fade-out $duration ease-out $delay both;
}

@mixin truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}