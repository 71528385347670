@import "mixins";
@import "variables";

.news-article {
    margin-bottom: 1rem;

    .image {
        width: 100%;
        margin-bottom: 1rem;

        img {
            object-fit: cover;
            aspect-ratio: 5 / 3;
        }
    }

    .title {
        a {
            color: $black;

            &:hover {
                color: $purple;
            }
        }
    }

    .link {
        color: $purple;
        font-weight: 700;
        position: relative;
        padding-right: 30px;

        &:hover {
            color: $light-blue;
        }

        &:after {
            content: url(../Images/arrow.png);
        }
    }
}