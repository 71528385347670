@import "mixins";
@import "variables";

#menu {
    background-color: $purple;
    color: $white;
    text-align: right;
    padding: 0 15px;
    height: 50px;
    top: 0;
    z-index: 50;

    @media screen and (min-width: $screen-lg-min) {
        background-color: $white;
        box-shadow: 0 10px 10px rgba(0, 0, 0, 0.05);
        color: $black;
        height: unset;
        position: sticky;
    }

    .menu-text {
        float: left;
        line-height: 50px;
    }

    .navbar {
        padding: 0 15px;

        @media screen and (min-width: $screen-lg-min) {
            padding: 0;
        }

        .navbar-brand {
            color: white;
        }
    }
    //DESKTOP MENU
    #menu-navbar {
        position: relative;
        width: inherit;

        > ul {
            display: flex;
            flex-direction: row;
            padding-left: 0;
            margin-bottom: 0;
            list-style: none;
            position: absolute;
            width: inherit;
            justify-content: center;
            //stops menu flicker on mobile
            bottom: 0;
            right: -9999px;

            @media screen and (min-width: $screen-lg-min) {
                position: static;
            }

            li {
                background: none;

                a { // Responsive menu item styles.
                    color: $black;
                    font-weight: 700;
                    font-size: 1.2rem;
                    display: inline-block;
                    line-height: 60px;
                    transition: background-color 0.3s ease, color 0.3s ease;
                    padding: 0 3rem;

                    &:hover {
                        color: $light-blue;
                        text-decoration: none;
                    }
                }

                &.dropdown:hover .dropdown-menu { // Dropdown menu hover trigger.
                    display: block;
                    @include fade-in(0.5s);
                }

                .dropdown-menu { // Standard Dropdown menu styles.
                    background: rgba(1, 40, 94, 0.95);
                    border: none;
                    border-radius: 0;
                    margin: 0;
                    min-width: 250px;
                    padding: 0;

                    li a {
                        display: block;
                        color: $white;
                        font-size: 1rem;
                        font-weight: 400;
                        line-height: 50px;
                        padding: 0 1rem;

                        &:hover {
                            background-color: $purple;
                        }
                    }
                }
            }
        }
    }
}

.navbar-toggler {
    border: 0;
    padding: 0;
    line-height: 50px;

    &:focus {
        outline: 0;
    }
}

.navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
    height: 2.5em;
    width: 2.5em;
}

// Mobile Menu Styling
.mm-spn {
    &.mm-spn--light {
        background-color: $dark-blue;
        color: white;
        font-weight: 700;
        font-size: 17px;
    }

    ul {
        background-color: white;
        color: $black;
        border: 0;
        border-radius: 0;

        li {
            &:before {
                width: 14px;
                height: 14px;
            }

            a {
                font-size: 17px;
                font-weight: 700;
            }

            &:after {
                margin-left: 0;
            }
        }
    }
}

:root {
    --mm-ocd-width: 85%;
    --mm-ocd-min-width: 200px;
    --mm-ocd-max-width: 500px;
    --mm-spn-item-height: 50px;
    --mm-spn-item-indent: 25px;
    --mm-spn-line-height: 24px;
    --mm-spn-panel-offset: 30%;
}
