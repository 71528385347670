@import "variables";

.carousel-move-one .col-12 {
    @media all and (max-width: $screen-sm-max) {
        padding-left: 0;
        padding-right: 0;
    }
}

.carousel-move-one .carousel-control-next, .carousel-move-one .carousel-control-prev {
    position: static;
    margin-top: 30px;
}

.carousel-move-one .cloned-item {
    display: none;
}

.carousel-move-one .carousel-inner .carousel-item.active,
.carousel-move-one .carousel-inner .carousel-item-start,
.carousel-move-one .carousel-inner .carousel-item-next,
.carousel-move-one .carousel-inner .carousel-item-prev {
    display: flex;
}

@media all and (min-width: $screen-md-min) {
    .carousel-move-one .carousel-inner > .active.carousel-item-start,
    .carousel-move-one .carousel-inner > .carousel-item-prev {
        left: -50%;
    }

    .carousel-move-one .carousel-inner > .active.carousel-item-end,
    .carousel-move-one .carousel-inner > .carousel-item-next {
        left: 50%;
    }

    .carousel-move-one .carousel-inner > .carousel-item-start,
    .carousel-move-one .carousel-inner > .carousel-item-prev.carousel-item-end,
    .carousel-move-one .carousel-inner > .active {
        left: 0;
    }

    .carousel-move-one .carousel-inner .cloned-item-1 {
        display: flex;
    }
}

@media all and (min-width: $screen-md-min) and (transform-3d), all and (min-width: $screen-md-min) and (-webkit-transform-3d) {
    .carousel-move-one .carousel-inner > .active.carousel-item-start,
    .carousel-move-one .carousel-inner > .carousel-item-prev {
        left: 0;
        transform: translate3d(-50%, 0, 0);
    }
}

@media all and (min-width: $screen-md-min) and (transform-3d), all and (min-width: $screen-md-min) and (-webkit-transform-3d) {
    .carousel-move-one .carousel-inner > .active.carousel-item-end,
    .carousel-move-one .carousel-inner > .carousel-item-next {
        left: 0;
        transform: translate3d(50%, 0, 0);
    }
}

@media all and (min-width: $screen-md-min) and (transform-3d), all and (min-width: $screen-md-min) and (-webkit-transform-3d) {
    .carousel-move-one .carousel-inner > .carousel-item-start,
    .carousel-move-one .carousel-inner > .carousel-item-prev.carousel-item-end,
    .carousel-move-one .carousel-inner > .active {
        left: 0;
        transform: translate3d(0, 0, 0);
    }
}

@media all and (min-width: $screen-lg-min) {
    .carousel-move-one .carousel-inner > .active.carousel-item-start,
    .carousel-move-one .carousel-inner > .carousel-item-prev {
        left: -33.333%;
    }

    .carousel-move-one .carousel-inner > .active.carousel-item-end,
    .carousel-move-one .carousel-inner > .carousel-item-next {
        left: 33.333%;
    }

    .carousel-move-one .carousel-inner > .carousel-item-start,
    .carousel-move-one .carousel-inner > .carousel-item-prev.carousel-item-end,
    .carousel-move-one .carousel-inner > .active {
        left: 0;
    }

    .carousel-move-one .carousel-inner .cloned-item-1,
    .carousel-move-one .carousel-inner .cloned-item-2 {
        display: flex;
    }
}

@media all and (min-width: $screen-lg-min) and (transform-3d), all and (min-width: $screen-lg-min) and (-webkit-transform-3d) {
    .carousel-move-one .carousel-inner > .active.carousel-item-start,
    .carousel-move-one .carousel-inner > .carousel-item-prev {
        left: 0;
        transform: translate3d(-33.333%, 0, 0);
    }
}

@media all and (min-width: $screen-lg-min) and (transform-3d), all and (min-width: $screen-lg-min) and (-webkit-transform-3d) {
    .carousel-move-one .carousel-inner > .active.carousel-item-end,
    .carousel-move-one .carousel-inner > .carousel-item-next {
        left: 0;
        transform: translate3d(33.333%, 0, 0);
    }
}

@media all and (min-width: $screen-lg-min) and (transform-3d), all and (min-width: $screen-lg-min) and (-webkit-transform-3d) {
    .carousel-move-one .carousel-inner > .carousel-item-start,
    .carousel-move-one .carousel-inner > .carousel-item-prev.carousel-item-end,
    .carousel-move-one .carousel-inner > .active {
        left: 0;
        transform: translate3d(0, 0, 0);
    }
}
