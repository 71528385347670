@import "mixins";
@import "variables";

#back-to-top {
    background: $dark-blue;
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
    bottom: 0;
    display: none;
    height: 50px;
    right: 15px;
    position: fixed;
    width: 100px;
    z-index: 10;
    @include fade-in(.5s, 0s);

    &.show {
        display: inline-block;
    }

    a {
        display: block;
        padding: 10px 0;
        text-align: center;

        img {
            max-height: 20px;
        }
    }
}

#top {
    background-color: $dark-blue;
    color: $white;
    font-size: 0.8rem;
    height: 40px;
    text-align: center;

    a {
        color: $white;

        &:hover {
            color: $light-blue;
            text-decoration: none;
        }
    }

    .contact-details, #top-links {
        line-height: 40px;
    }

    .contact-details {
        float: left;
        display: flex;
        align-items: center;
        column-gap: 1rem;

        img {
            margin-right: 0.5rem;
        }

        .telephone, .email {
            a:last-child {
                display: none;

                @media screen and (min-width: $screen-sm-min) {
                    display: inline-block;
                }
            }
        }
    }

    img {
        height: 21px;
    }

    #top-links {
        text-align: right;

        ul {
            li {
                display: inline-block;

                a {
                    border-right: solid $white 1px;
                    padding: 0 0.6rem 0 0.5rem;
                }

                &:last-child {
                    a {
                        border-right: none;
                        padding-right: 0;
                    }
                }
            }
        }
    }
}

#app > header {
    padding: 0.5rem 0;

    @media screen and (min-width: $screen-lg-min) {
        padding: 0.5rem 0 0 0;
    }

    .d-flex {
        column-gap: 2rem;

        @media screen and (min-width: $screen-lg-min) {
            column-gap: 6rem;
            border-bottom: solid 2px $light-grey;
            padding-bottom: 0.5rem;
        }

        .logo {
            img {
                height: 60px;
                padding: 0.25rem 0;

                @media screen and (min-width: $screen-sm-min) {
                    height: 80px;
                }

                @media screen and (min-width: $screen-lg-min) {
                    height: 90px;
                }
            }
        }

        .product-search {
            margin-top: 0.5rem;
            flex-grow: 1;
            width: 100%;
            order: 3;

            @media screen and (min-width: $screen-md-min) {
                margin-top: unset;
                order: unset;
                width: auto;
            }
        }
    }

    @include clearfix;
}

#breadcrumb {
    font-size: 0.8rem;
    margin-bottom: 15px;
    margin-top: 15px;
    padding: 0 15px;
    text-transform: lowercase;

    ol {
        list-style: none;
        margin-bottom: 0;
        padding-left: 0;

        li {
            display: inline-block;

            &.last a {
                color: inherit;

                &:hover {
                    cursor: text;
                    text-decoration: none;
                }
            }
        }
    }
}

#outer-wrapper > main {
    display: flex;
    margin-top: 3rem;

    > section, > aside {
        min-width: 0; // this fixes an issue when using the truncate mixin (see https://css-tricks.com/flexbox-truncated-text/).
    }
}

#content {
    flex: 1;
    padding: 2rem 0 1rem;
}

#left-column, #right-column {
    flex: 0 0 250px; // flex-grow flex-shrink flex-basis

    @media screen and (min-width: $screen-lg-min) {
        flex-basis: 340px;
    }
}

#left-column {
    display: none;

    @media screen and (min-width: $screen-lg-min) {
        display: block;
    }
}

#right-column {
    display: none;

    @media screen and (min-width: $screen-xl-min) {
        display: block;
    }
}

#footer-top {
    position: relative;
    z-index: -1;
    margin-bottom: -2px;

    @media screen and (min-width: $screen-xl-min) {
        margin-bottom: -50px;
    }

    @media screen and (min-width: 2000px) {
        display: none;
    }
}

#app > footer {
    background-color: $dark-blue;
    color: white;
    padding: 4rem 0;
    position: relative;
    text-align: center;

    @media screen and (min-width: $screen-md-min) {
        text-align: left;
        padding: 0 0 4rem 0;
    }

    @media screen and (min-width: 2000px) {
        padding: 4rem 0;
    }

    a {
        color: white;

        @media (hover: hover) {
            &:hover {
                color: $light-blue;
            }
        }
    }

    .logo {
        height: 100px;
        margin-bottom: 2rem;
        position: relative
    }

    .footer-header, header {
        font-size: 1.5rem;
        font-family: Hind;
        font-weight: 700;
        margin-bottom: 1rem;
        border-bottom: solid 2px rgba(255,255,255,.15);
        padding-bottom: 0.8rem;
        cursor: pointer;
        position: relative;
        text-align: left;

        p {
            margin-bottom: 0;
        }

        @media screen and (min-width: $screen-md-min) {
            border-bottom: 0;
            cursor: default;

            &:after {
                background: none !important;
            }
        }

        &[aria-expanded="true"] {
            &:after {
                transform: rotate(0.25turn);
            }
        }

        &:after {
            background: url(../Images/ChevronRight.png);
            background-repeat: no-repeat;
            background-position: 100% 50%;
            position: absolute;
            content: "";
            width: 11px;
            height: 18px;
            top: 0.8rem;
            bottom: 0;
            right: 5px;
            transition: transform 0.3s ease;
        }
    }

    .footer-contact-details {
        font-size: 0.8rem;

        .telephone, .email {
            font-size: 1rem;
            margin-bottom: 0.5rem;

            a {
                font-family: Hind;
                font-size: 1.8rem;
                font-weight: 700;
            }
        }
    }

    .footer-content {
        margin-top: 15px;

        @media screen and (min-width: $screen-md-min) {
            display: block;
            height: auto !important;
            visibility: visible;
            margin-top: 0;
            overflow: unset;
        }

        &.show {
            display: block !important;
        }

        &.sectors-footer {
            ul {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
            }
        }

        ul {
            list-style: none;
            margin-left: 0;
            padding-left: 0;
        }
    }
}

#app > #bottom {
    background-color: $light-blue;
    color: white;
    font-size: 0.9rem;
    padding: 0.75rem;
    line-height: 20px;
    text-align: center;

    ul, p {
        line-height: 20px;
    }

    ul {
        margin-bottom: 0;

        .list-inline-item {
            line-height: 20px;
        }

        .list-inline-item:not(:last-child) {
            margin-right: .75rem;
            padding-right: .75rem;
            border-right: solid 1px $white;
        }
    }

    a {
        color: $white;
    }
}