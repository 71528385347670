@import url("https://fonts.googleapis.com/css2?family=Hind:wght@400;500;700&family=Nunito:ital,wght@0,400;0,700;1,400&display=swap");
body {
  color: #232323;
  font: 0.9rem/1.7 "Nunito", sans-serif;
  -ms-overflow-style: scrollbar; /* prevent ie scrollbar overlay */
  font-display: swap;
}
@media screen and (min-width: 768px) {
  body {
    font: 1rem/1.7 "Nunito", sans-serif;
  }
}

a {
  color: #5CA2D8;
  text-decoration: none;
  transition: color 0.3s ease;
}
a:focus {
  outline: none;
  text-decoration: none;
}
@media (hover: hover) {
  a:hover {
    color: #8A479B;
  }
}

h1, h2, h3, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  color: #070707;
  font-family: Hind;
  font-weight: 700;
}

h1, .h1 {
  margin-top: 1rem;
  margin-bottom: 2rem;
}
h1 span, .h1 span {
  color: #8A479B;
  font-size: 1.4rem;
  font-weight: 400;
}

h2, h3, h3, h4, h5, h6, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 1rem;
}

code {
  border: 1px solid black;
}

label {
  font-weight: 700;
}

hr {
  background-color: #EEE;
  border: 0;
  height: 1px;
  margin: 1rem 0;
}

img {
  max-width: 100%;
}

[v-cloak] {
  display: none;
}

*[style*="display: none"] {
  display: none !important;
}

*[style*="display: block"] {
  display: block !important;
}
#back-to-top {
  background: #001E34;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  bottom: 0;
  display: none;
  height: 50px;
  right: 15px;
  position: fixed;
  width: 100px;
  z-index: 10;
  animation: fade-in 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) 0s both;
}
#back-to-top.show {
  display: inline-block;
}
#back-to-top a {
  display: block;
  padding: 10px 0;
  text-align: center;
}
#back-to-top a img {
  max-height: 20px;
}

#top {
  background-color: #001E34;
  color: #FFF;
  font-size: 0.8rem;
  height: 40px;
  text-align: center;
}
#top a {
  color: #FFF;
}
#top a:hover {
  color: #5CA2D8;
  text-decoration: none;
}
#top .contact-details, #top #top-links {
  line-height: 40px;
}
#top .contact-details {
  float: left;
  display: flex;
  align-items: center;
  column-gap: 1rem;
}
#top .contact-details img {
  margin-right: 0.5rem;
}
#top .contact-details .telephone a:last-child, #top .contact-details .email a:last-child {
  display: none;
}
@media screen and (min-width: 576px) {
  #top .contact-details .telephone a:last-child, #top .contact-details .email a:last-child {
    display: inline-block;
  }
}
#top img {
  height: 21px;
}
#top #top-links {
  text-align: right;
}
#top #top-links ul li {
  display: inline-block;
}
#top #top-links ul li a {
  border-right: solid #FFF 1px;
  padding: 0 0.6rem 0 0.5rem;
}
#top #top-links ul li:last-child a {
  border-right: none;
  padding-right: 0;
}

#app > header {
  padding: 0.5rem 0;
}
@media screen and (min-width: 992px) {
  #app > header {
    padding: 0.5rem 0 0 0;
  }
}
#app > header .d-flex {
  column-gap: 2rem;
}
@media screen and (min-width: 992px) {
  #app > header .d-flex {
    column-gap: 6rem;
    border-bottom: solid 2px #EEE;
    padding-bottom: 0.5rem;
  }
}
#app > header .d-flex .logo img {
  height: 60px;
  padding: 0.25rem 0;
}
@media screen and (min-width: 576px) {
  #app > header .d-flex .logo img {
    height: 80px;
  }
}
@media screen and (min-width: 992px) {
  #app > header .d-flex .logo img {
    height: 90px;
  }
}
#app > header .d-flex .product-search {
  margin-top: 0.5rem;
  flex-grow: 1;
  width: 100%;
  order: 3;
}
@media screen and (min-width: 768px) {
  #app > header .d-flex .product-search {
    margin-top: unset;
    order: unset;
    width: auto;
  }
}
#app > header:before, #app > header:after {
  content: " ";
  display: table;
}
#app > header:after {
  clear: both;
}

#breadcrumb {
  font-size: 0.8rem;
  margin-bottom: 15px;
  margin-top: 15px;
  padding: 0 15px;
  text-transform: lowercase;
}
#breadcrumb ol {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}
#breadcrumb ol li {
  display: inline-block;
}
#breadcrumb ol li.last a {
  color: inherit;
}
#breadcrumb ol li.last a:hover {
  cursor: text;
  text-decoration: none;
}

#outer-wrapper > main {
  display: flex;
  margin-top: 3rem;
}
#outer-wrapper > main > section, #outer-wrapper > main > aside {
  min-width: 0;
}

#content {
  flex: 1;
  padding: 2rem 0 1rem;
}

#left-column, #right-column {
  flex: 0 0 250px;
}
@media screen and (min-width: 992px) {
  #left-column, #right-column {
    flex-basis: 340px;
  }
}

#left-column {
  display: none;
}
@media screen and (min-width: 992px) {
  #left-column {
    display: block;
  }
}

#right-column {
  display: none;
}
@media screen and (min-width: 1200px) {
  #right-column {
    display: block;
  }
}

#footer-top {
  position: relative;
  z-index: -1;
  margin-bottom: -2px;
}
@media screen and (min-width: 1200px) {
  #footer-top {
    margin-bottom: -50px;
  }
}
@media screen and (min-width: 2000px) {
  #footer-top {
    display: none;
  }
}

#app > footer {
  background-color: #001E34;
  color: white;
  padding: 4rem 0;
  position: relative;
  text-align: center;
}
@media screen and (min-width: 768px) {
  #app > footer {
    text-align: left;
    padding: 0 0 4rem 0;
  }
}
@media screen and (min-width: 2000px) {
  #app > footer {
    padding: 4rem 0;
  }
}
#app > footer a {
  color: white;
}
@media (hover: hover) {
  #app > footer a:hover {
    color: #5CA2D8;
  }
}
#app > footer .logo {
  height: 100px;
  margin-bottom: 2rem;
  position: relative;
}
#app > footer .footer-header, #app > footer header {
  font-size: 1.5rem;
  font-family: Hind;
  font-weight: 700;
  margin-bottom: 1rem;
  border-bottom: solid 2px rgba(255, 255, 255, 0.15);
  padding-bottom: 0.8rem;
  cursor: pointer;
  position: relative;
  text-align: left;
}
#app > footer .footer-header p, #app > footer header p {
  margin-bottom: 0;
}
@media screen and (min-width: 768px) {
  #app > footer .footer-header, #app > footer header {
    border-bottom: 0;
    cursor: default;
  }
  #app > footer .footer-header:after, #app > footer header:after {
    background: none !important;
  }
}
#app > footer .footer-header[aria-expanded=true]:after, #app > footer header[aria-expanded=true]:after {
  transform: rotate(0.25turn);
}
#app > footer .footer-header:after, #app > footer header:after {
  background: url(../Images/ChevronRight.png);
  background-repeat: no-repeat;
  background-position: 100% 50%;
  position: absolute;
  content: "";
  width: 11px;
  height: 18px;
  top: 0.8rem;
  bottom: 0;
  right: 5px;
  transition: transform 0.3s ease;
}
#app > footer .footer-contact-details {
  font-size: 0.8rem;
}
#app > footer .footer-contact-details .telephone, #app > footer .footer-contact-details .email {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}
#app > footer .footer-contact-details .telephone a, #app > footer .footer-contact-details .email a {
  font-family: Hind;
  font-size: 1.8rem;
  font-weight: 700;
}
#app > footer .footer-content {
  margin-top: 15px;
}
@media screen and (min-width: 768px) {
  #app > footer .footer-content {
    display: block;
    height: auto !important;
    visibility: visible;
    margin-top: 0;
    overflow: unset;
  }
}
#app > footer .footer-content.show {
  display: block !important;
}
#app > footer .footer-content.sectors-footer ul {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
#app > footer .footer-content ul {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
}

#app > #bottom {
  background-color: #5CA2D8;
  color: white;
  font-size: 0.9rem;
  padding: 0.75rem;
  line-height: 20px;
  text-align: center;
}
#app > #bottom ul, #app > #bottom p {
  line-height: 20px;
}
#app > #bottom ul {
  margin-bottom: 0;
}
#app > #bottom ul .list-inline-item {
  line-height: 20px;
}
#app > #bottom ul .list-inline-item:not(:last-child) {
  margin-right: 0.75rem;
  padding-right: 0.75rem;
  border-right: solid 1px #FFF;
}
#app > #bottom a {
  color: #FFF;
}
body.one-column-layout #content {
  padding: 0 0 15px;
}

body.two-columns-layout #content {
  padding-right: 0;
}

body.home-page {
  /*#app > header {
      box-shadow: none;
      position: absolute;
      z-index: 3000;
      width: 100%;
      top: 40px;

      .cart-text p, .cart-text a, .subtotal {
          color: #FFF;
      }

      .cart-text {
          border-right: solid 1px rgba(255,255,255,0.6);
      }

      #menu {
          background-color: transparent;
          box-shadow: none;

          #menu-navbar > ul li a {
              color: #FFF;
          }

          .navbar {
              border-top: solid 2px rgba(255,255,255,0.6);
          }
      }

      .shop-search-widget {
          ::placeholder {
              color: #FFF;
          }

          input {
              background-color: transparent;
              border-color: rgba(255,255,255,0.6);
          }
      }
  }*/
}
body.home-page #breadcrumb {
  display: none;
}
body.home-page #outer-wrapper {
  background-image: url(../Images/HomeBackground.png);
  background-size: 110%;
  background-position: center center;
  background-repeat: no-repeat;
}
@media screen and (min-width: 768px) {
  body.home-page #outer-wrapper {
    background-size: cover;
  }
}
@media screen and (min-width: 768px) {
  body.home-page .home-page-text {
    padding-left: 5rem;
  }
}
.btn-check:active + .btn-outline-primary, .btn-check:checked + .btn-outline-primary, .btn-outline-primary:active {
  background-color: #8A479B;
  border-color: #8A479B;
  box-shadow: none !important;
}

.btn-primary {
  background-color: #8A479B;
  border-color: #8A479B;
  border-radius: 0;
  box-shadow: none !important;
}
@media (hover: hover) {
  .btn-primary:hover {
    background-color: #001E34;
    border-color: #001E34;
  }
}
.btn-primary:focus {
  background-color: #001E34;
  border-color: #001E34;
}

.btn-secondary {
  background-color: transparent;
  border-width: 2px;
  border-color: #001E34;
  border-radius: 0;
  color: #001E34;
  box-shadow: none !important;
}
@media (hover: hover) {
  .btn-secondary:hover {
    background-color: #001E34;
    color: #FFF;
  }
}
.btn-secondary:focus {
  background-color: #001E34;
  border-color: #001E34;
  color: #FFF;
}

.btn-lg {
  font-size: 1rem;
  padding: 0.75rem 1.5rem;
}

.field-validation-error {
  color: #a94442;
  display: inline-block;
  margin-top: 0.2rem;
}

.form-check-input:focus, .form-control:focus, .form-select:focus {
  box-shadow: none;
}

.form-check-input {
  margin-top: 0.25rem;
}
.form-check-input:checked {
  background-color: #001E34;
  border-color: #001E34;
}

.form-group {
  margin-bottom: 1rem;
}
.form-group.required > .form-label:after {
  content: " *";
}

.form-control, .form-select {
  background-color: #EEE;
  border-radius: 0;
  border-color: #EEE;
  transition: border-color 0.3s ease;
}
.form-control:hover, .form-control:focus, .form-select:hover, .form-select:focus {
  border-color: #8A479B;
}
.form-control:disabled, .form-select:disabled {
  background-color: #EEE;
  color: #c1c1c1;
  opacity: 0.8;
}

.has-error .form-control, .has-error .form-select {
  border-color: #a94442;
}
.has-error .form-file .form-select {
  border-color: #ced4da;
}

select.form-control {
  appearance: auto;
}

.modal .modal-dialog {
  max-width: 800px;
}

.validation-summary-errors {
  background-color: #f2dEde;
  border: 2px solid #a94442;
  border-radius: 0.25rem;
  font-weight: bold;
  margin: 1rem 0;
  padding: 1rem;
}
.validation-summary-errors span {
  display: block;
}
.validation-summary-errors ul {
  margin-bottom: 0;
}

.validation-summary-valid {
  display: none;
}
#menu {
  background-color: #8A479B;
  color: #FFF;
  text-align: right;
  padding: 0 15px;
  height: 50px;
  top: 0;
  z-index: 50;
}
@media screen and (min-width: 992px) {
  #menu {
    background-color: #FFF;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.05);
    color: #070707;
    height: unset;
    position: sticky;
  }
}
#menu .menu-text {
  float: left;
  line-height: 50px;
}
#menu .navbar {
  padding: 0 15px;
}
@media screen and (min-width: 992px) {
  #menu .navbar {
    padding: 0;
  }
}
#menu .navbar .navbar-brand {
  color: white;
}
#menu #menu-navbar {
  position: relative;
  width: inherit;
}
#menu #menu-navbar > ul {
  display: flex;
  flex-direction: row;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  position: absolute;
  width: inherit;
  justify-content: center;
  bottom: 0;
  right: -9999px;
}
@media screen and (min-width: 992px) {
  #menu #menu-navbar > ul {
    position: static;
  }
}
#menu #menu-navbar > ul li {
  background: none;
}
#menu #menu-navbar > ul li a {
  color: #070707;
  font-weight: 700;
  font-size: 1.2rem;
  display: inline-block;
  line-height: 60px;
  transition: background-color 0.3s ease, color 0.3s ease;
  padding: 0 3rem;
}
#menu #menu-navbar > ul li a:hover {
  color: #5CA2D8;
  text-decoration: none;
}
#menu #menu-navbar > ul li.dropdown:hover .dropdown-menu {
  display: block;
  animation: fade-in 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) 0s both;
}
#menu #menu-navbar > ul li .dropdown-menu {
  background: rgba(1, 40, 94, 0.95);
  border: none;
  border-radius: 0;
  margin: 0;
  min-width: 250px;
  padding: 0;
}
#menu #menu-navbar > ul li .dropdown-menu li a {
  display: block;
  color: #FFF;
  font-size: 1rem;
  font-weight: 400;
  line-height: 50px;
  padding: 0 1rem;
}
#menu #menu-navbar > ul li .dropdown-menu li a:hover {
  background-color: #8A479B;
}

.navbar-toggler {
  border: 0;
  padding: 0;
  line-height: 50px;
}
.navbar-toggler:focus {
  outline: 0;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  height: 2.5em;
  width: 2.5em;
}

.mm-spn.mm-spn--light {
  background-color: #001E34;
  color: white;
  font-weight: 700;
  font-size: 17px;
}
.mm-spn ul {
  background-color: white;
  color: #070707;
  border: 0;
  border-radius: 0;
}
.mm-spn ul li:before {
  width: 14px;
  height: 14px;
}
.mm-spn ul li a {
  font-size: 17px;
  font-weight: 700;
}
.mm-spn ul li:after {
  margin-left: 0;
}

:root {
  --mm-ocd-width: 85%;
  --mm-ocd-min-width: 200px;
  --mm-ocd-max-width: 500px;
  --mm-spn-item-height: 50px;
  --mm-spn-item-indent: 25px;
  --mm-spn-line-height: 24px;
  --mm-spn-panel-offset: 30%;
}
#app > header .widget > .container, #menu .widget > .container, #app > main .widget > .container {
  padding: 0 !important;
  width: auto !important;
}
#app > header .widget > .container:after, #menu .widget > .container:after, #app > main .widget > .container:after {
  clear: none;
}

#main-bottom .container > header, #main-bottom header, #main-top .container > header, #main-top header {
  color: #001E34;
  font-size: calc(1.5rem + 1.5vw);
  font-weight: 700;
  font-family: Hind;
  margin-bottom: 30px;
  text-align: center;
  line-height: 1.2;
}
@media screen and (min-width: 1200px) {
  #main-bottom .container > header, #main-bottom header, #main-top .container > header, #main-top header {
    font-size: 2.75rem;
  }
}
#main-bottom .container > header span, #main-bottom header span, #main-top .container > header span, #main-top header span {
  color: #8A479B;
  font-size: 1.4rem;
  font-weight: 400;
}
#main-bottom header, #main-top header {
  text-align: left;
}

#main-top .widget {
  margin: 5rem 0;
}

#main-bottom .widget {
  margin: 4rem 0;
}

html.sr .reveal, html.sr .sector-wrapper, html.sr .news-article, html.sr .service, html.sr .cable-assemblies {
  visibility: hidden;
}
.account-area img {
  border: 5px solid transparent;
  border-radius: 50%;
  transition: all 0.3s ease;
}
.account-area a {
  color: #070707;
}
@media (hover: hover) {
  .account-area a:hover {
    color: #8A479B;
  }
  .account-area a:hover img {
    border-color: #8A479B;
  }
}
.account-area h5 {
  margin-top: 1rem;
}

.alert {
  border-width: 2px;
}

.alert-danger {
  background-color: #f2dEde;
  border-color: #a94442;
}

.banner-home {
  margin-top: 0 !important;
  margin-bottom: 6rem !important;
  position: relative;
}
@media screen and (min-width: 992px) {
  .banner-home {
    margin-bottom: 9rem !important;
  }
}
.banner-home .hero-banner {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 100vh;
  min-height: 400px;
  max-height: 600px;
  width: 100%;
}
@media screen and (min-width: 992px) {
  .banner-home .hero-banner {
    height: 80vh;
    min-height: 800px;
    max-height: 1200px;
  }
}
.banner-home .hero-banner .overlay {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  display: flex;
  align-items: flex-end;
  height: 100%;
  width: 100%;
}
.banner-home .hero-banner .overlay .container {
  color: #FFF;
}
.banner-home .hero-banner .overlay .container .banner-text {
  font-weight: 500;
  font-size: 2rem;
  font-family: Hind;
  line-height: 1.2;
  max-width: 100%;
  text-align: center;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
  margin: 0 auto 8rem auto;
  padding: 0 3rem;
}
@media screen and (min-width: 768px) {
  .banner-home .hero-banner .overlay .container .banner-text {
    font-size: 3rem;
    text-align: left;
  }
}
@media screen and (min-width: 1200px) {
  .banner-home .hero-banner .overlay .container .banner-text {
    padding: 0;
  }
}
.banner-home .carousel-controls {
  position: absolute;
  width: 150px;
  bottom: 3rem;
  height: 50px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
@media screen and (min-width: 768px) {
  .banner-home .carousel-controls {
    width: 100%;
    bottom: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.banner-home .carousel-control-prev, .banner-home .carousel-control-next {
  background-color: #5CA2D8;
  height: 50px;
  width: 50px;
  opacity: 0.8;
  border-radius: 50px;
}
.banner-home .carousel-control-prev:hover, .banner-home .carousel-control-next:hover {
  opacity: 1;
}
@media screen and (min-width: 768px) {
  .banner-home .carousel-control-next {
    margin-right: 3rem;
  }
}
@media screen and (min-width: 768px) {
  .banner-home .carousel-control-prev {
    margin-left: 3rem;
  }
}

.banner {
  margin-top: 0 !important;
  margin-bottom: 9rem !important;
  position: relative;
  overflow-x: clip;
}
.banner.inner {
  margin-bottom: 3rem !important;
}
.banner.inner .hero-banner {
  clip-path: polygon(0 0, 100% 0, 100% 90%, 0 100%);
}
.banner.inner .hero-banner .overlay {
  background: none !important;
  display: flex;
  align-items: flex-start;
}
.banner.inner .hero-banner .overlay .container .banner-text {
  background-color: #5CA2D8;
  display: inline-block;
  padding: 2rem;
  width: auto;
  align-self: center;
  margin-top: 100px;
}
.banner.inner .hero-banner .overlay .container .banner-text .title {
  margin-bottom: 0;
}
.banner.inner .hero-banner .overlay .container .banner-text .text {
  font-size: 1rem;
}
.banner.inner .hero-banner .overlay .container .banner-text .link {
  color: #FFF;
}
.banner .feature {
  position: absolute;
  z-index: 3;
  bottom: -100px;
  right: 0;
  animation: fade-in 1s cubic-bezier(0.39, 0.575, 0.565, 1) 1s both;
}
@media screen and (min-width: 768px) {
  .banner .feature {
    bottom: -50px;
    width: 400px;
  }
}
@media screen and (min-width: 992px) {
  .banner .feature {
    bottom: 0;
    width: 500px;
  }
}
@media screen and (min-width: 1200px) {
  .banner .feature {
    width: 600px;
  }
}
@media screen and (min-width: 1400px) {
  .banner .feature {
    width: 800px;
  }
}
.banner .feature img {
  height: 200px;
}
@media screen and (min-width: 768px) {
  .banner .feature img {
    height: auto;
  }
}
.banner .ribbon {
  position: absolute;
  z-index: 2;
  top: 0;
  display: none;
  animation: fade-in 1s cubic-bezier(0.39, 0.575, 0.565, 1) 0.75s both;
}
@media screen and (min-width: 992px) {
  .banner .ribbon {
    display: block;
    right: -300px;
    width: 800px;
  }
}
@media screen and (min-width: 1400px) {
  .banner .ribbon {
    right: -200px;
  }
}
.banner .hero-banner {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  clip-path: polygon(0 0%, 100% 0, 100% 100%, 0 95%);
  height: auto;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .banner .hero-banner {
    clip-path: polygon(0 0%, 100% 0, 100% 100%, 0 90%);
  }
}
@media screen and (min-width: 992px) {
  .banner .hero-banner {
    height: 75vh;
    min-height: 600px;
    max-height: 1000px;
    clip-path: polygon(0 0%, 100% 0, 100% 100%, 0 80%);
  }
}
.banner .hero-banner .overlay {
  background: rgba(4, 37, 62, 0.85);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}
.banner .hero-banner .overlay .container {
  color: #FFF;
}
.banner .hero-banner .overlay .container .banner-text {
  max-width: 100%;
  padding: 3rem 3rem 120px 3rem;
  width: 600px;
  text-align: center;
  margin: 0 auto;
}
@media screen and (min-width: 768px) {
  .banner .hero-banner .overlay .container .banner-text {
    text-align: left;
    margin: unset;
  }
}
@media screen and (min-width: 992px) {
  .banner .hero-banner .overlay .container .banner-text {
    width: 600px;
    padding: 6rem 0 0 0;
    text-align: left;
    margin: unset;
  }
}
@media screen and (min-width: 992px) {
  .banner .hero-banner .overlay .container .banner-text .text {
    font-size: 1.4rem;
  }
}
.banner .hero-banner .overlay .container .banner-text .title {
  font-size: 2rem;
  font-family: Hind;
  font-weight: 700;
  line-height: 1.2;
}
@media screen and (min-width: 992px) {
  .banner .hero-banner .overlay .container .banner-text .title {
    font-size: 3.6rem;
  }
}

.banner-bottom .hero-banner {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: calc(100vh - 200px);
  width: 100%;
}
.banner-bottom .hero-banner .overlay {
  background: linear-gradient(270deg, #001E34 0%, rgba(0, 30, 52, 0.5) 100%);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}
@media screen and (min-width: 992px) {
  .banner-bottom .hero-banner .overlay {
    background: linear-gradient(270deg, #001E34 0%, rgba(0, 30, 52, 0.15) 100%);
    justify-content: flex-end;
  }
}
.banner-bottom .hero-banner .overlay .banner-text {
  color: #FFF;
  font-size: 1rem;
  text-align: center;
  padding: 0 1rem;
}
@media screen and (min-width: 992px) {
  .banner-bottom .hero-banner .overlay .banner-text {
    width: 650px;
    max-width: 100%;
    float: right;
    margin-right: 10%;
    text-align: left;
  }
}
.banner-bottom .hero-banner .overlay .banner-text .text {
  margin-bottom: 4rem;
}
.banner-bottom .hero-banner .overlay .banner-text header {
  color: #FFF !important;
  text-align: center !important;
}
@media screen and (min-width: 992px) {
  .banner-bottom .hero-banner .overlay .banner-text header {
    text-align: left !important;
  }
}

.bespoke-solutions .container {
  border-top: solid 3px #EEE;
  border-bottom: solid 3px #EEE;
}
.bespoke-solutions .container .max-container {
  width: 1000px;
  margin: 3rem auto;
  max-width: 100%;
}

.cable-assemblies {
  margin-bottom: 6rem !important;
}
.cable-assemblies .container {
  display: block;
  align-items: center;
  position: relative;
}
@media screen and (min-width: 768px) {
  .cable-assemblies .container {
    display: flex;
  }
}
.cable-assemblies .container .text {
  position: static;
  padding: 0;
  margin-bottom: 15px;
  background-color: #EEE;
  z-index: 5;
  right: unset;
  padding: 2rem 2rem 0 2rem;
}
@media screen and (min-width: 768px) {
  .cable-assemblies .container .text {
    position: absolute;
    margin-bottom: 0;
    max-width: 100%;
    width: 600px;
    top: -4rem;
    right: 2rem;
  }
}
@media screen and (min-width: 992px) {
  .cable-assemblies .container .text {
    position: absolute;
    margin-bottom: 0;
    top: unset;
    right: 0.75rem;
  }
}
.cable-assemblies .container .image {
  margin-right: auto;
  position: relative;
  width: 900px;
  max-width: 100%;
}
@media screen and (min-width: 992px) {
  .cable-assemblies .container .image img {
    clip-path: polygon(0 0%, 100% 0, 85% 100%, 0 100%);
  }
}

.card {
  margin-bottom: 1rem;
}
.card .card-header {
  background-color: white;
  border-bottom: 0;
  padding-bottom: 0;
  padding-top: 1.25rem;
}
.card .card-header h2 {
  margin-bottom: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.contact-form {
  width: 900px;
  max-width: 100%;
  margin: 3rem auto 0 auto;
}
.contact-form .btn {
  width: 100%;
  font-size: 1rem;
  padding: 0.75rem 1.5rem;
}
.contact-form .card {
  border: none;
  box-shadow: none !important;
}
.contact-form .card .card-body {
  padding: 0;
}
.contact-form .card .form-group:nth-child(1), .contact-form .card .form-group:nth-child(3) {
  float: left;
  margin-right: 0;
  width: 100%;
}
@media screen and (min-width: 576px) {
  .contact-form .card .form-group:nth-child(1), .contact-form .card .form-group:nth-child(3) {
    width: calc(50% - 15px);
    margin-right: 30px;
  }
}
.contact-form .card .form-group:nth-child(2), .contact-form .card .form-group:nth-child(4) {
  float: left;
  width: 100%;
}
@media screen and (min-width: 576px) {
  .contact-form .card .form-group:nth-child(2), .contact-form .card .form-group:nth-child(4) {
    width: calc(50% - 15px);
  }
}
.contact-form .card .form-group:last-child {
  clear: both;
}
.contact-form .card textarea {
  width: 100%;
  height: 400px;
}
.contact-form .card input[type=text], .contact-form .card input[type=password], .contact-form .card select {
  height: 50px;
  line-height: 38px; /* make sure text is vertically aligned centrally (height - padding - border) */
  width: 100%;
}
.contact-form .card .card-header {
  display: none;
}

@media screen and (max-width: 991px) {
  .container, .container-md, .container-sm {
    max-width: 100%;
  }
}

.link {
  display: inline-block;
  font-weight: 700;
  position: relative;
  padding-right: 30px;
}
.link:after {
  content: url(../Images/arrowwhite.png);
  position: absolute;
  top: 1px;
  bottom: 0;
  right: 0;
}

.pagination {
  margin-bottom: 0;
}
.pagination .page-item .page-link {
  color: #001E34;
}
.pagination .page-item.active .page-link {
  background-color: #001E34;
  border-color: #001E34;
  color: white;
}

.sectors-we-specialise-in {
  background-color: #001E34;
  font-size: 1.1rem;
  padding: 7rem 0 47rem 0;
  clip-path: polygon(0 0%, 100% 5%, 100% 100%, 0 80%);
  color: #FFF;
}
@media screen and (min-width: 768px) {
  .sectors-we-specialise-in {
    clip-path: polygon(0 0%, 100% 10%, 100% 100%, 0 80%);
    padding: 10rem 0 50rem 0;
  }
}
@media screen and (min-width: 992px) {
  .sectors-we-specialise-in {
    clip-path: polygon(0 0%, 100% 15%, 100% 100%, 0 80%);
    padding: 14rem 0 50rem 0;
  }
}
.sectors-we-specialise-in header {
  color: #FFF !important;
}
.sectors-we-specialise-in header span {
  color: #5CA2D8 !important;
}

.sectors {
  margin-top: -50rem !important;
}
.sectors .sector-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1rem;
}
@media screen and (min-width: 576px) {
  .sectors .sector-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (min-width: 1200px) {
  .sectors .sector-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
.sectors .sector-grid .sector-wrapper {
  height: 250px;
  overflow: hidden;
  position: relative;
}
.sectors .sector-grid .sector-wrapper .sector {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 100%;
  width: 100%;
  display: block;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  transition: all 0.5s ease;
}
@media screen and (min-width: 768px) {
  .sectors .sector-grid .sector-wrapper .sector {
    background-size: 115%;
  }
}
.sectors .sector-grid .sector-wrapper .sector:hover {
  cursor: pointer;
}
@media screen and (min-width: 768px) {
  .sectors .sector-grid .sector-wrapper .sector:hover {
    background-size: 120%;
  }
}
.sectors .sector-grid .sector-wrapper .sector .overlay {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.2) 42%, rgba(0, 0, 0, 0.3) 56%, rgba(0, 0, 0, 0.9) 100%);
  height: 100%;
  left: 0;
  position: absolute;
  overflow: hidden;
  top: 0;
  width: 100%;
  transform-style: preserve-3d;
  transition: background-color 0.3s ease-in;
}
.sectors .sector-grid .sector-wrapper .sector .overlay:hover {
  background-color: rgba(92, 162, 216, 0.8);
  text-decoration: none;
}
.sectors .sector-grid .sector-wrapper .sector .overlay .text {
  color: #FFF;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 0 1rem;
  z-index: 2;
}
.sectors .sector-grid .sector-wrapper .sector .overlay .text h4 {
  color: #FFF;
  margin-bottom: 0;
}

.services {
  margin-bottom: 9rem !important;
}
.services .service {
  margin-top: 1rem;
}
.services .service .image {
  background-color: #D1EBFF;
}
.services .service .image:hover img {
  clip-path: polygon(0 0%, 100% 0, 100% 100%, 0 100%);
}
.services .service .image img {
  clip-path: polygon(0 0%, 100% 0, 100% 100%, 0 90%);
  transition: clip-path 0.3s ease;
}
.services .service .text {
  background-color: #D1EBFF;
  padding: 1rem 1.5rem;
}
.services .service .text h4 a {
  color: #070707;
}
.services .service .text h4 a:hover {
  color: #8A479B;
}

.table td {
  vertical-align: middle;
}

.talk-to-our-experts .blue-box {
  background-color: #001E34;
  border-radius: 1rem;
  color: #FFF;
  padding: 2rem;
}
.talk-to-our-experts .blue-box .row {
  align-items: center;
}
.talk-to-our-experts .blue-box .row h2 {
  color: #FFF;
}
.talk-to-our-experts .blue-box .row p {
  margin-bottom: 0;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.news-article {
  margin-bottom: 1rem;
}
.news-article .image {
  width: 100%;
  margin-bottom: 1rem;
}
.news-article .image img {
  object-fit: cover;
  aspect-ratio: 5/3;
}
.news-article .title a {
  color: #070707;
}
.news-article .title a:hover {
  color: #8A479B;
}
.news-article .link {
  color: #8A479B;
  font-weight: 700;
  position: relative;
  padding-right: 30px;
}
.news-article .link:hover {
  color: #5CA2D8;
}
.news-article .link:after {
  content: url(../Images/arrow.png);
}
@media all and (max-width: 767px) {
  .carousel-move-one .col-12 {
    padding-left: 0;
    padding-right: 0;
  }
}

.carousel-move-one .carousel-control-next, .carousel-move-one .carousel-control-prev {
  position: static;
  margin-top: 30px;
}

.carousel-move-one .cloned-item {
  display: none;
}

.carousel-move-one .carousel-inner .carousel-item.active,
.carousel-move-one .carousel-inner .carousel-item-start,
.carousel-move-one .carousel-inner .carousel-item-next,
.carousel-move-one .carousel-inner .carousel-item-prev {
  display: flex;
}

@media all and (min-width: 768px) {
  .carousel-move-one .carousel-inner > .active.carousel-item-start,
  .carousel-move-one .carousel-inner > .carousel-item-prev {
    left: -50%;
  }
  .carousel-move-one .carousel-inner > .active.carousel-item-end,
  .carousel-move-one .carousel-inner > .carousel-item-next {
    left: 50%;
  }
  .carousel-move-one .carousel-inner > .carousel-item-start,
  .carousel-move-one .carousel-inner > .carousel-item-prev.carousel-item-end,
  .carousel-move-one .carousel-inner > .active {
    left: 0;
  }
  .carousel-move-one .carousel-inner .cloned-item-1 {
    display: flex;
  }
}
@media all and (min-width: 768px) and (transform-3d), all and (min-width: 768px) and (-webkit-transform-3d) {
  .carousel-move-one .carousel-inner > .active.carousel-item-start,
  .carousel-move-one .carousel-inner > .carousel-item-prev {
    left: 0;
    transform: translate3d(-50%, 0, 0);
  }
}
@media all and (min-width: 768px) and (transform-3d), all and (min-width: 768px) and (-webkit-transform-3d) {
  .carousel-move-one .carousel-inner > .active.carousel-item-end,
  .carousel-move-one .carousel-inner > .carousel-item-next {
    left: 0;
    transform: translate3d(50%, 0, 0);
  }
}
@media all and (min-width: 768px) and (transform-3d), all and (min-width: 768px) and (-webkit-transform-3d) {
  .carousel-move-one .carousel-inner > .carousel-item-start,
  .carousel-move-one .carousel-inner > .carousel-item-prev.carousel-item-end,
  .carousel-move-one .carousel-inner > .active {
    left: 0;
    transform: translate3d(0, 0, 0);
  }
}
@media all and (min-width: 992px) {
  .carousel-move-one .carousel-inner > .active.carousel-item-start,
  .carousel-move-one .carousel-inner > .carousel-item-prev {
    left: -33.333%;
  }
  .carousel-move-one .carousel-inner > .active.carousel-item-end,
  .carousel-move-one .carousel-inner > .carousel-item-next {
    left: 33.333%;
  }
  .carousel-move-one .carousel-inner > .carousel-item-start,
  .carousel-move-one .carousel-inner > .carousel-item-prev.carousel-item-end,
  .carousel-move-one .carousel-inner > .active {
    left: 0;
  }
  .carousel-move-one .carousel-inner .cloned-item-1,
  .carousel-move-one .carousel-inner .cloned-item-2 {
    display: flex;
  }
}
@media all and (min-width: 992px) and (transform-3d), all and (min-width: 992px) and (-webkit-transform-3d) {
  .carousel-move-one .carousel-inner > .active.carousel-item-start,
  .carousel-move-one .carousel-inner > .carousel-item-prev {
    left: 0;
    transform: translate3d(-33.333%, 0, 0);
  }
}
@media all and (min-width: 992px) and (transform-3d), all and (min-width: 992px) and (-webkit-transform-3d) {
  .carousel-move-one .carousel-inner > .active.carousel-item-end,
  .carousel-move-one .carousel-inner > .carousel-item-next {
    left: 0;
    transform: translate3d(33.333%, 0, 0);
  }
}
@media all and (min-width: 992px) and (transform-3d), all and (min-width: 992px) and (-webkit-transform-3d) {
  .carousel-move-one .carousel-inner > .carousel-item-start,
  .carousel-move-one .carousel-inner > .carousel-item-prev.carousel-item-end,
  .carousel-move-one .carousel-inner > .active {
    left: 0;
    transform: translate3d(0, 0, 0);
  }
}
.fade-in {
  animation: fade-in 1s cubic-bezier(0.39, 0.575, 0.565, 1) 0s both;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fade-out {
  animation: fade-out 1s ease-out 0s both;
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.text-grey {
  color: #dee2e6;
}

.text-lg {
  font-size: 1.2rem;
}

.text-sm {
  font-size: 0.8rem;
}
.brand {
  display: table;
  margin-bottom: 15px;
  text-align: center;
}
.brand a {
  background-color: white;
  border: 1px solid #ccc;
  display: table-cell;
  height: 150px;
  vertical-align: middle;
  width: 150px;
}

#cart .card {
  /* Resetting the box model properties */
  display: block;
  width: auto;
  margin: 0;
  padding: 0;
  /* Resetting the visual styles */
  background-color: #fff;
  border: none;
  border-radius: 0;
  box-shadow: none;
}
#cart .card.shadow {
  box-shadow: unset !important;
}
#cart .card-header,
#cart .card-footer {
  /* Resetting the background and border styles for header and footer */
  background-color: transparent;
  border: none;
}
#cart .card-body {
  /* Resetting the padding and margin for the body */
  padding: 0;
  margin: 0;
}

.cart-items > :not(caption) > * > *, .order-lines > :not(caption) > * > * {
  border-bottom-width: 3px;
  border-color: #EEE;
  padding: 2rem 0.5rem;
}
.cart-items th, .order-lines th {
  padding: 0.5rem 0.5rem;
}
.cart-items .description > a, .order-lines .description > a {
  color: #070707;
  font-size: 1.1rem;
  font-weight: 700;
}
@media (hover: hover) {
  .cart-items .description > a:hover, .order-lines .description > a:hover {
    color: #001E34;
  }
}
.cart-items .item, .cart-items .line, .order-lines .item, .order-lines .line {
  background-color: #FFF;
}
.cart-items .child, .order-lines .child {
  background-color: #f8f8f8;
}
.cart-items .image, .order-lines .image {
  align-items: center;
}

.cart-summary-container {
  background-color: #EEE;
  padding: 1rem;
}
.cart-summary-container .btn {
  width: 100%;
}
.cart-summary-container .card {
  background-color: #EEE;
  box-shadow: none !important;
  border: none;
}
.cart-summary-container .card .card-body {
  background-color: #EEE;
  padding: 0;
}
.cart-summary-container .card .card-body .form-control {
  background-color: #FFF;
  border-color: #FFF;
}
.cart-summary-container .card .card-body .form-control:hover {
  border-color: #001E34;
}
.cart-summary-container .card .discount h2, .cart-summary-container .card .summary h2, .cart-summary-container .card .delivery h2 {
  font-size: 1.6rem;
}
.cart-summary-container .card .delivery-methods {
  background-color: #fff;
  padding: 1rem;
  margin-bottom: 1rem;
  max-height: 300px;
  overflow-y: auto;
}
.cart-summary-container .card .delivery-methods .delivery-method {
  align-items: center;
  padding: 0.25rem 0;
}
.cart-summary-container .card hr {
  display: none;
}
.cart-summary-container .card .summary, .cart-summary-container .card .totals {
  margin-bottom: 1rem;
}

.cart-widget {
  position: relative;
}
.cart-widget .cart-summary {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}
@media screen and (min-width: 992px) {
  .cart-widget .cart-summary .cart-text {
    border-right: solid 1px #EEE;
    padding-right: 1rem;
  }
}
.cart-widget .cart-summary .cart-text .shop-link {
  color: #070707;
}
.cart-widget .cart-summary .cart-text .shop-link:hover {
  color: #8A479B;
}
.cart-widget .cart-summary .subtotal {
  color: #5CA2D8;
  font-size: 1.1rem;
  padding: 0 1rem;
  display: none;
}
@media screen and (min-width: 992px) {
  .cart-widget .cart-summary .subtotal {
    display: block;
  }
}
.cart-widget .cart-summary .cart-icon {
  align-self: baseline;
}
.cart-widget .cart-items {
  background-color: #8A479B;
  border-radius: 50%;
  color: #fff !important;
  font-size: 0.7rem;
  line-height: 25px;
  height: 25px;
  position: absolute;
  text-align: center;
  right: -10px;
  top: 0;
  width: 25px;
}
.cart-widget .dropdown {
  background-color: white;
  border: 1px solid #888;
  border-top: 4px solid #001E34;
  color: #070707;
  margin-top: -4px;
  padding: 15px;
  position: absolute;
  right: 0;
  width: 380px;
  z-index: 200;
}
.cart-widget .dropdown .item {
  border-bottom: 1px solid #001E34;
  min-height: 80px;
  padding: 10px;
}
.cart-widget .dropdown .item .img {
  height: 50px;
}
.cart-widget .dropdown .item:hover {
  background-color: #eaeaea;
}
@media screen and (max-width: 991px) {
  .cart-widget .dropdown {
    display: none;
  }
}

#featured-products .carousel-control-next, #featured-products .carousel-control-prev, .top-sellers .carousel-control-next, .top-sellers .carousel-control-prev {
  border: solid 2px #001E34;
  border-radius: 100%;
  height: 50px;
  width: 50px;
  margin-top: 0;
}
#featured-products .carousel-control-next-icon, #featured-products .carousel-control-prev-icon, .top-sellers .carousel-control-next-icon, .top-sellers .carousel-control-prev-icon {
  width: 1rem;
  height: 1rem;
}
#featured-products .carousel-control-next-icon, .top-sellers .carousel-control-next-icon {
  background-image: url(../Images/next.svg);
}
#featured-products .carousel-control-prev-icon, .top-sellers .carousel-control-prev-icon {
  background-image: url(../Images/previous.svg);
}
#featured-products .product-listing, .top-sellers .product-listing {
  margin-bottom: 1rem;
}

.filter-and-sort.sort, .filter-and-sort.filter {
  display: flex;
  align-items: center;
  column-gap: 5px;
  font-weight: 700;
  padding: 0.75rem;
}
@media screen and (min-width: 576px) {
  .filter-and-sort.sort, .filter-and-sort.filter {
    padding: 0.75rem 1.5rem;
  }
}
.filter-and-sort.sort img, .filter-and-sort.filter img {
  display: none;
}
@media screen and (min-width: 576px) {
  .filter-and-sort.sort img, .filter-and-sort.filter img {
    display: block;
  }
}
.filter-and-sort.sort:after, .filter-and-sort.filter:after {
  content: "";
  display: inline-block;
  border: 0;
  mask-image: url(../Images/Shop/dropdown.svg);
  background-repeat: no-repeat;
  background-position: center center;
  margin-left: 0.5rem;
  height: 7px;
  width: 13px;
  top: 3px;
}
.filter-and-sort.filter:after {
  background-color: white;
}
.filter-and-sort.sort {
  background-color: transparent;
  border: solid 2px #EEE;
  color: #070707;
}
.filter-and-sort.sort:after {
  background-color: black;
}
@media (hover: hover) {
  .filter-and-sort.sort:hover {
    background-color: #EEE;
  }
}

.flag {
  color: #FFF;
  font-size: 0.8rem;
  font-weight: 700;
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 0.25rem 0.5rem;
  text-transform: uppercase;
  z-index: 3;
}
.flag.red, .flag.sale {
  background-color: #AC1734;
}
.flag.light-blue {
  background-color: #001E34;
}
.flag.dark-blue {
  background-color: #001E34;
}
.flag.purple {
  background-color: #001E34;
}

.product-listing {
  margin-bottom: 3rem;
}
.product-listing.enquiry .product-image {
  border: solid 1px #001E34;
  margin-bottom: 0;
  padding: 1rem;
}
.product-listing.enquiry .summary {
  background-color: #001E34;
  color: #FFF;
  padding: 2rem 1rem 1rem 1rem;
}
.product-listing.enquiry .summary h4, .product-listing.enquiry .summary h4 a {
  color: #FFF;
}
.product-listing.enquiry .summary h4:hover, .product-listing.enquiry .summary h4 a:hover {
  color: #5CA2D8;
}
.product-listing.enquiry .summary .product-summary {
  margin-bottom: 2rem;
}
.product-listing .product-image {
  background-color: #FFF;
  display: block;
  margin-bottom: 1.5rem;
  position: relative;
  width: 100%;
  height: 250px;
}
@media screen and (min-width: 768px) {
  .product-listing .product-image {
    height: 300px;
  }
}
.product-listing .product-image img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.product-listing .product-name {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}
.product-listing .product-name a {
  color: #001E34;
}
@media (hover: hover) {
  .product-listing .product-name a:hover {
    color: #8A479B;
  }
}
.product-listing .product-price {
  font-family: Hind;
  font-weight: 700;
  font-size: 1.6rem;
}
.product-listing .product-price .from {
  font-size: 0.8rem;
  font-weight: 400;
}
.product-listing .product-price .strikethrough {
  text-decoration: line-through;
  font-size: 1rem;
}

.product-listing-categories {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 30px;
  row-gap: 30px;
  margin: 2rem 0;
}
@media screen and (min-width: 768px) {
  .product-listing-categories {
    grid-template-columns: repeat(3, 1fr);
  }
}
.product-listing-categories .product-listing-category {
  text-align: center;
}
.product-listing-categories .product-listing-category .category-image {
  margin-bottom: 1rem;
  width: 100%;
}
.product-listing-categories .product-listing-category .category-image img {
  object-fit: cover;
  aspect-ratio: 4/3;
}
.product-listing-categories .product-listing-category h4 a {
  color: #001E34;
}
@media (hover: hover) {
  .product-listing-categories .product-listing-category h4 a:hover {
    color: #8A479B;
  }
}

.product-listing-details > .row {
  padding-bottom: 2rem;
}
.product-listing-details .carousel-indicators {
  list-style: none;
  margin: 10px auto 0;
  overflow: hidden;
  position: static;
  width: auto;
  flex-wrap: wrap;
}
.product-listing-details .carousel-indicators button, .product-listing-details .carousel-indicators button.active {
  width: 100px;
  height: 70px;
  background-color: #fff;
  border-radius: 0;
  padding: 0.5rem;
  position: relative;
  margin: 5px;
}
.product-listing-details .carousel-indicators img {
  position: absolute;
  width: 100%;
  height: auto;
  max-height: 100px;
  margin: auto;
  padding: 10px;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.product-listing-details .product-selection-wrapper {
  padding: 0;
}
@media screen and (min-width: 768px) {
  .product-listing-details .product-selection-wrapper {
    padding: 0 1rem;
  }
}
.product-listing-details .product-selection-wrapper h1 {
  margin-bottom: 0.5rem;
}
.product-listing-details .product-selection-wrapper .form-group.quantity {
  margin-top: 1rem;
  margin-bottom: 2rem;
  text-align: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.product-listing-details .product-selection-wrapper .form-group.quantity .form-label {
  text-align: left;
  width: 100%;
}
.product-listing-details .product-selection-wrapper .form-group.quantity input {
  background-color: white;
  width: 135px;
  text-align: center;
}
.product-listing-details .product-selection-wrapper .form-group.quantity .btn {
  line-height: 48px;
  width: calc(100% - 150px);
}
.product-listing-details .product-selection-wrapper .enquiry-product {
  background-color: #EEE;
  border-radius: 1rem;
  margin-top: 1.5rem;
  padding: 2rem 2rem 1rem 2rem;
}
.product-listing-details .product-selection-wrapper .need-help {
  background-color: #EEE;
  border-radius: 1rem;
  padding: 2rem 2rem 1rem 2rem;
  text-align: center;
}
.product-listing-details .product-selection-wrapper .price {
  font-family: Hind;
  font-size: 1.8rem;
  font-weight: 700;
}
.product-listing-details .product-selection-wrapper .price.enquiry {
  color: #8A479B;
}
.product-listing-details .product-selection-wrapper .price .from {
  font-size: 1rem;
  font-weight: 400;
}
.product-listing-details .product-selection-wrapper .price .sale-price {
  font-size: 1rem;
  font-weight: 400;
  text-decoration: line-through;
}
.product-listing-details .product-selection-wrapper:has(.stock-status) .sku {
  border-top: none;
}
.product-listing-details .product-selection-wrapper .sku {
  border-top: solid 1px #CFCFCF;
  border-bottom: solid 1px #CFCFCF;
  padding: 0.5rem 0;
}
.product-listing-details .product-selection-wrapper .stock-status {
  border-top: solid 1px #CFCFCF;
  border-bottom: solid 1px #CFCFCF;
  margin-bottom: 0;
  margin-top: 0.5rem;
  padding: 0.5rem 0;
  display: flex;
}
.product-listing-details .product-selection-wrapper .stock-status:before {
  content: "";
  background-image: url(../Images/Shop/Delivery.png);
  height: 24px;
  width: 39px;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center center;
  margin-right: 0.5rem;
  transition: background-color 0.3s ease;
}
.product-listing-details .product-image {
  cursor: pointer;
  display: block;
  height: 300px;
  margin: 10px auto;
  padding: 0;
  position: relative;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .product-listing-details .product-image {
    height: 500px;
  }
}
.product-listing-details .product-image img {
  margin: auto;
  max-height: 300px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
@media screen and (min-width: 768px) {
  .product-listing-details .product-image img {
    max-height: 500px;
  }
}

.product-listing-details-body {
  background-color: #001E34;
  color: #FFF;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 97%);
  padding: 3rem 0 6rem 0;
}
@media screen and (min-width: 768px) {
  .product-listing-details-body {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 90%);
    padding: 3rem 0 12rem 0;
  }
}
.product-listing-details-body .h1, .product-listing-details-body h2, .product-listing-details-body h3, .product-listing-details-body h4, .product-listing-details-body h5, .product-listing-details-body h6 {
  color: #FFF;
}
.product-listing-details-body .h1 span, .product-listing-details-body h2 span, .product-listing-details-body h3 span, .product-listing-details-body h4 span, .product-listing-details-body h5 span, .product-listing-details-body h6 span {
  color: #5CA2D8;
}

.shop-categories {
  margin-bottom: 3rem;
}
.shop-categories .intro-box {
  background-color: #001E34;
  color: #FFF;
  padding: 30px 45px;
}
@media screen and (min-width: 768px) {
  .shop-categories .intro-box {
    height: 300px;
  }
}
.shop-categories .intro-box h4 {
  color: #FFF;
  margin-bottom: 1rem;
}
.shop-categories .intro-box .sub-heading {
  font-size: 1.4rem;
  font-weight: 200;
}
.shop-categories .intro-box .text {
  width: 70%;
}
.shop-categories .shop-category {
  background-size: cover;
  display: block;
  height: 300px;
  text-align: center;
  position: relative;
  margin-top: 1rem;
}
@media screen and (min-width: 768px) {
  .shop-categories .shop-category {
    margin-top: 0;
  }
}
.shop-categories .shop-category:hover .overlay {
  background-color: rgba(0, 0, 0, 0.4) !important;
}
.shop-categories .shop-category .overlay {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 35%, rgba(0, 0, 0, 0.55) 70%, rgba(0, 0, 0, 0.95) 100%);
  bottom: 0;
  color: #fff;
  display: block;
  position: absolute;
  height: 100%;
  right: 0;
  text-align: right;
  width: 100%;
  vertical-align: bottom;
  -o-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}
.shop-categories .shop-category .text {
  bottom: 0;
  color: #FFF;
  left: 1rem;
  margin: 1rem 0 0 0;
  padding: 10px 0;
  position: absolute;
  text-align: left;
  width: calc(100% - 2rem);
}
.shop-categories .shop-category .text h4 {
  color: #FFF;
  margin-bottom: 0;
}
.shop-categories .shop-category .text p {
  margin-bottom: 0;
}

.shop-search-widget {
  position: relative;
}
@media screen and (min-width: 768px) {
  .shop-search-widget {
    width: 100%;
  }
}
.shop-search-widget ::placeholder {
  color: #001E34;
}
.shop-search-widget input[type=text] {
  background-color: #FFF;
  border: solid 2px #EEE;
  border-radius: 0;
  color: #001E34;
  float: left;
  height: 54px;
  line-height: 45px;
  width: 100%;
}
.shop-search-widget input[type=text]:hover, .shop-search-widget input[type=text]:focus {
  border-color: #8A479B;
}
.shop-search-widget button {
  border: none !important;
  height: 50px;
  line-height: 50px;
  width: 24px;
  position: absolute;
  top: 2px;
  right: 15px;
}
.shop-search-widget button.btn {
  background: url(/assets/theme/images/search.svg) no-repeat center center;
  text-indent: -9999px;
}
@media (hover: hover) {
  .shop-search-widget button.btn:hover {
    background: url(/assets/theme/images/search.svg) no-repeat center center !important;
  }
}
.shop-search-widget .dropdown {
  background-color: white;
  border: 1px solid #888;
  left: 0;
  padding: 15px;
  position: absolute;
  top: 54px;
  width: 100%;
  z-index: 200;
}
.shop-search-widget .dropdown .title {
  font-weight: 700;
}