@import "variables";

#app > header, #menu, #app > main {
    .widget > .container {
        padding: 0 !important;
        width: auto !important;

        &:after {
            clear: none;
        }
    }
}

#main-bottom, #main-top {
    .container > header, header {
        color: $dark-blue;
        font-size: calc(1.5rem + 1.5vw);
        font-weight: 700;
        font-family: Hind;
        margin-bottom: 30px;
        text-align: center;
        line-height: 1.2;

        @media screen and (min-width: $screen-xl-min) {
            font-size: 2.75rem;
        }

        span {
            color: $purple;
            font-size: 1.4rem;
            font-weight: 400;
        }
    }

    header {
        text-align: left;
    }
}

#main-top .widget {
    margin: 5rem 0;
}

#main-bottom .widget {
    margin: 4rem 0;
}

html.sr .reveal, html.sr .sector-wrapper, html.sr .news-article, html.sr .service, html.sr .cable-assemblies {
    visibility: hidden;
}
